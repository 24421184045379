.container {
  .logoSection {
    margin: auto;
    text-align: center;

    img {
      width: 52px;
      margin-bottom: 32px;
    }
    .title {
      font-family: "NanumMyeongjo";
      font-size: 30px;
      font-weight: 300;
      color: #2e2e2e;
    }
    .subTitle {
      margin: 16px auto 0;
      text-align: center;
      font-size: 16px;
      line-height: 180%;
      font-weight: 300;
      width: 80vw;
      max-width: 694px;
    }
    .telContainer {
      font-size: 17px;
      font-weight: 600;
      color: #0d4455;

      span {
        @media (max-width: 1023px) {
          display: inline-block;
        }
      }
    }
  }

  .listContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .item {
      border-radius: 100%;
      border: 1px solid #bdbdbd;
      display: flex;
      flex-direction: column;
      width: min(40vw, 250px);
      height: min(40vw, 250px);
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      cursor: pointer;

      @media (max-width: 1023px) {
        width: min(60vw, 250px);
        height: min(60vw, 250px);
      }

      .title {
        font-size: 24px;
        color: #bebebe;
        font-weight: 600;

        @media (max-width: 1023px) {
          font-size: 20px;
        }
      }
      .subTitle {
        font-size: 20px;
        color: #bebebe;

        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
      .div {
        width: 30%;
        height: 1.5px;
        background-color: gray;
        margin: 16px auto;
      }
      .content {
        font-size: 16px;
        color: #bdbdbd;
        word-break: keep-all;
        padding: 0 12px;

        @media (max-width: 1023px) {
          font-size: 12px;
        }

        img {
          width: min(40%, 200px);
        }
      }
    }
  }
}
