@keyframes bottomToTop {
  from {
    top: -100svh;
  }
  to {
    top: 0;
  }
}
@keyframes topToBottom {
  from {
    bottom: -100svh;
  }
  to {
    bottom: 0;
  }
}
@keyframes rightToLeft {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}
@keyframes leftToRight {
  from {
    right: 100vw;
  }
  to {
    right: 0;
  }
}
@keyframes bottomToTopClosing {
  from {
    top: 0;
  }
  to {
    top: -100svh;
  }
}
@keyframes topToBottomClosing {
  from {
    bottom: 0;
  }
  to {
    bottom: -100svh;
  }
}
@keyframes rightToLeftClosing {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}
@keyframes leftToRightClosing {
  from {
    right: 0;
  }
  to {
    right: 100vw;
  }
}

.slideModal {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: 100vw;
  height: 100svh;

  .wrap {
    width: 100vw;
    height: 100%;
    position: relative;

    &.isClosing {
      &.way-bottom {
        animation: bottomToTopClosing 0.3s;
      }
      &.way-top {
        animation: topToBottomClosing 0.3s;
      }
      &.way-right {
        animation: rightToLeftClosing 0.3s;
      }
      &.way-left {
        animation: leftToRightClosing 0.3s;
      }
    }
    &:not(.isClosing) {
      &.way-bottom {
        animation: bottomToTop 0.5s;
      }
      &.way-top {
        animation: topToBottom 0.5s;
      }
      &.way-right {
        animation: rightToLeft 0.5s;
      }
      &.way-left {
        animation: leftToRight 0.5s;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 100%;

      .background {
        position: absolute;
        width: 100vw;
        height: 100svh;
      }
      .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: absolute;

        &.isFull {
          width: 100vw;
          height: 100svh;
        }
        &.isCenter {
          width: 100vw;
          bottom: 0;
        }
      }
    }
  }
}
