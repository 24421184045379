.container {
  flex: 1;

  .logoSection {
    margin: auto;
    text-align: center;

    img {
      width: 52px;
      margin-bottom: 32px;
    }
    .title {
      font-family: "NanumMyeongjo";
      font-size: 30px;
      font-weight: 300;
      color: #2e2e2e;
    }
    .subTitle {
      margin: 4px auto 0;
      text-align: center;
      font-size: 16px;
      line-height: 180%;
      font-weight: 300;
      width: 80vw;
      max-width: 694px;
      color: #a9a9a9;
    }
  }

  .galleryInfoContainer {
    display: flex;
    gap: 72px;
    text-align: left;
    margin-top: 181px;
    flex: 1;

    @media (max-width: 1023px) {
      margin-top: 100px;
      flex-direction: column;
    }

    .infoSection {
      max-width: min(80vw, 800px);
      margin: auto;
      width: initial;

      .infoTitle {
        font-family: "NanumMyeongjo";
        font-size: 30px;
        color: #0d4455;
      }

      .infoTitleDiv {
        width: 45px;
        background: black;
        margin: 36px 0 23px;
        height: 1px;
      }

      .infoContent {
        display: flex;
        flex-direction: column;
        margin-top: 34px;
        font-size: 13px;
        min-width: min(80vw, 380px);
        gap: 12px;

        @media (min-width: 1024px) {
          max-width: min(35vw, 435px);
        }

        .item {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          text-align: left;
          font-size: 16px;
          white-space: pre-wrap;

          .itemTitle {
            font-weight: 600;
            min-width: 182px;
            margin-right: 20px;
          }
        }
      }
    }

    .gallery {
      flex: 1;

      img {
        object-fit: fill;
        height: 100%;
        cursor: pointer;
      }

      .selectedGallery {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        aspect-ratio: calc(700 / 490);

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .mapContainer {
    margin: 128px auto;

    @media (max-width: 1023px) {
      margin-top: 72px;
    }
  }

  .weddingContainer {
    margin-top: 90px;

    .mainImgContainer {
      :global(.slick-track) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      ul {
        list-style: none;
        padding-left: 0px;
      }

      .bannerPaging {
        flex: 1;
        display: flex !important;
        flex-direction: row;
        padding-top: 10px;
        margin: 0 auto;
        width: 100%;
        align-items: center;
        justify-content: center;
        z-index: 1;
        bottom: 20px;

        > li {
          .dots {
            width: 10px;
            height: 10px;
            background-color: #bbb;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;
          }

          &:global(.slick-active) {
            .dots {
              background-color: #717171;
            }
          }
        }
      }
    }

    .reservationSection {
      margin-top: 150px;
      width: 100%;
      background: #0d4455;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 73px;
      padding-bottom: 73px;

      .title {
        font-size: 25px;
        font-weight: 500;
        color: white;
      }
      .subTitle {
        margin-top: 5px;
        font-size: 13px;
        color: #929292;
      }
      .btn {
        width: min(80vw, 226px);
        padding: 13px 0;
        color: #0d4455;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 17px;
        gap: 8px;
        border-radius: 7px;
        font-size: 13px;

        img {
          width: 22px;
        }
      }
    }

    .gallerySection {
      margin-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-family: "NanumMyeongjo";
        font-size: 30px;
        font-weight: 300;

        .pageTitle {
          font-family: "NanumMyeongjo";
        }

        > div {
          display: inline-block;
        }
      }
      .subTitle {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 5px;
        color: #a9a9a9;
      }
      .div {
        margin: 35px auto 72px;
        width: min(90vw, 156px);
        height: 2px;
        background: #000000;
      }

      .listContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        flex-direction: row;

        .imgItem {
          flex: 1;
          min-width: 20%;
          aspect-ratio: 1;

          img {
            width: 100%;
            height: 100%;
          }

          &.empty {
            width: 100%;
            height: 100%;

            @media (min-width: 1024px) {
              background-color: #d9d9d9;
            }

            @media (max-width: 1023px) {
              height: 0;
              img {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.tov-wedding-page .tov-main-img {
  margin-top: 90px;
}
.tov-wedding-page .tov-main-img img {
  object-fit: fill;
  height: 100%;
}
@media (max-width: 1023px) {
  .tov-wedding-page .tov-container {
    margin-bottom: 72px;
  }
}
