.container {
  .logoSection {
    margin: auto;
    text-align: center;

    img {
      width: 52px;
      margin-bottom: 32px;
    }
    .title {
      font-family: "NanumMyeongjo";
      font-size: 30px;
      font-weight: 300;
      color: #2e2e2e;
    }
    .subTitle {
      margin: 16px auto 0;
      text-align: center;
      font-size: 16px;
      line-height: 180%;
      font-weight: 300;
      width: 80vw;
      max-width: 694px;
    }
  }
  .mapSection {
    margin: 60px auto 0;
  }
}
