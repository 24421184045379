.img {
  object-fit: cover;
  width: 100%;

  &.isCursor {
    cursor: pointer;
  }
}

.modalClass {
  left: 0;
  right: 0;

  .modalContainer {
    .modal {
      max-width: 80vw;
      width: 600px;
      background: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 8px;

      .close {
        border-bottom: 1px solid #bebebe;
        padding: 6px 12px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }
    }
  }
}
