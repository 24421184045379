.wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100svh;
  justify-content: center;

  .header {
    flex: unset;
  }

  .main {
    flex: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .footer {
    flex: unset;
  }
}

@media (max-width: 1024px) {
  .center {
    margin-right: 0;
  }
}
