.box {
  display: flex;
  flex: 1;
  border: 1px solid #000;
  overflow: hidden;

  &.focus {
    border: 1px solid var(--color-main);
  }

  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    padding: 4px 8px;
    min-height: 20px;
  }

  .rightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #d9d9d9;
    padding: 4px 8px;
  }
}
