.container {
  margin-top: 129px;
  margin-bottom: 172px;
  margin: 129px auto 172px;
  text-align: center;
  min-height: calc(
    100svh - 140px - 165px - 129px - 172px
  ); /* 100% - header - footer - margin */

  @media (min-width: 1024px) {
    max-width: min(80vw, 1170px);
  }
  @media (max-width: 1023px) {
    max-width: 90vw;
    margin-bottom: 72px;
  }
}
