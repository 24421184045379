.pageContainer {
  display: flex;
  right: 0;
  padding: 3px 0;
  justify-content: center;
  align-items: center;

  .paging {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .pageItem {
      min-width: 32px;
      height: 32px;
      border-radius: 8px;
      border: none;
      background: none;
      cursor: pointer;

      &.active {
        background: #000;
        color: #fff;
        font-weight: 700;
      }
    }

    .arrowBtn {
      font-size: 10px;
      padding: 0;
    }
  }
}
