.box {
  display: flex;
  flex: 1;
  border: 1px solid #000;
  overflow: hidden;

  &.focus {
    border: 1px solid var(--color-main);
  }

  textarea {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    resize: none;
  }
}
