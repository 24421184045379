.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .bannerContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100svh;

    @media (max-width: 768px) {
      height: 100svh;
    }

    .banner {
      height: 100svh;
    }

    ul {
      list-style: none;
      padding-left: 0px;
    }

    .bannerPaging {
      flex: 1;
      position: absolute;
      display: flex !important;
      flex-direction: row;
      bottom: 46px;
      margin: 0 auto;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 8px;
      z-index: 1;
      bottom: 20px;

      > li {
        .dots {
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: rgba(0, 0, 0, 0.33);
          cursor: pointer;
        }

        &:global(.slick-active) {
          .dots {
            background-color: rgba(0, 0, 0, 1);
          }
        }
      }
    }

    .tovMainBackground {
      display: flex;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100vw;
      height: 100svh;
      top: 0;
      align-items: center;
      justify-content: center;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: white;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        text-align: center;
        word-break: keep-all;
      }

      .tovMainBackgroundLogo {
        width: 50px;
        height: auto;
        margin-bottom: 24px;
        opacity: 0.5;
      }
      .tovMainBackgroundTitle {
        font-size: 36px;
        font-weight: 700;
        margin-top: 8px;
        margin-bottom: 8px;
      }
      .tovMainBackgroundSubTitle {
        color: white;
        font-size: 26px;
        font-weight: 200;

        @media (max-width: 1023px) {
          font-size: 20px;

          span {
            display: inline-block;
            width: 70vw;
            max-width: unset;
          }
        }
      }
      .tovMainBackgroundContent {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 6px;
      }
      .tovMainBackgroundDiv {
        max-width: 90vw;
        width: 400px;
        height: 1px;
        background: white;
        margin: 8px 0 14px;

        @media (max-width: 1023px) {
          width: 70vw;
          max-width: unset;
        }
      }
      .tovMainBackgroundBottomText {
        font-size: 14px;
        font-weight: 300;

        @media (max-width: 1023px) {
          width: 70vw;
          max-width: unset;
          span {
            display: inline-block;
          }
        }
      }
    }
  }

  .mainSnsIcon {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .mainSnsIconContainer {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 90px;
      right: 80px;
      gap: 30px;

      @media (max-width: 1023px) {
        bottom: 30px;
        right: 15px;
        gap: 10px;
      }

      .mainSnsIconItem {
        cursor: pointer;
        width: 46px;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

#wrap {
  display: flex;
  flex-direction: column;
}
#container {
  min-height: 100svh;
  flex: 1;
}

#header.on .tov-logo .tov-logo-white {
  display: none;
}
#header.on .tov-logo .tov-logo-black {
  display: block;
}
#header:not(.on) .tov-logo .tov-logo-white {
  display: block;
}
#header:not(.on) .tov-logo .tov-logo-black {
  display: none;
}

header#header {
  background: none !important;
}

@media (min-width: 1024px) {
  .smart-banner-bx-wrapper img {
    object-fit: unset;
  }
}

#header.layout3 .inner .top_nav_box .top_category > ul > li > a {
  color: white;
}

.tov-logo {
  color: white;
}
