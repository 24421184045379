.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .tovContent {
    @media (max-width: 1023px) {
      margin-top: 70px;
      margin-bottom: 110px;
    }
  }

  .logoSection {
    margin: auto;
    text-align: center;

    img {
      width: 52px;
      margin-bottom: 32px;
    }
    .title {
      font-family: "NanumMyeongjo";
      font-size: 30px;
      font-weight: 300;
      color: #2e2e2e;
    }
  }

  .contentSection {
    .gallery {
      display: flex;
      gap: 72px;
      text-align: left;
      margin-top: 150px;

      @media (max-width: 1023px) {
        flex-direction: column-reverse;
        margin-top: 70px;
      }

      .infoSection {
        @media (max-width: 1023px) {
          flex-direction: column-reverse;
        }

        .title {
          font-family: "NanumMyeongjo";
          font-size: 30px;
          color: #0d4455;
        }
        .div {
          width: 45px;
          background: black;
          margin: 36px 0 23px;
          height: 1px;
        }
        .infoContent {
          display: flex;
          flex-direction: column;
          gap: 23px;
          margin-top: 34px;
          font-size: 13px;
          min-width: min(80vw, 380px);

          .infoItem {
            line-height: 150%;
          }
        }
        .btnContainer {
          margin-top: 41px;

          button {
            padding: 6px 34px;
            font-size: 12px;
            line-height: 200%;
            background-color: #d9d9d9;
            color: #505050;
            border: none;
          }
        }
      }
      .gallerySection {
        flex: 1;

        @media (max-width: 1023px) {
          width: min(92vw, 100%);
          margin: auto;
        }

        img {
          object-fit: fill;
          height: 100%;
          cursor: pointer;
        }

        #selected-gallery {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          aspect-ratio: calc(700 / 490);

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .youtube {
    margin-top: 172px;

    @media (max-width: 1023px) {
      margin-top: 110px;
    }

    iframe {
      width: 100%;
      aspect-ratio: calc(560 / 315);
    }
  }
}

.story-page
  .tov-container
  .tov-content
  .gallery-with-info
  .gallery-section
  #selected-gallery {
  aspect-ratio: unset;
}
