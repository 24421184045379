.container {
  .titleRow {
    .title {
      color: #555555;
      font-size: 16px;
      font-weight: 700;
    }
    .subTitle {
      margin-top: 25px;
      font-size: 14px;
      color: #6d6d6d;
    }
  }

  .pcListContainer {
    margin-top: 42px;

    @media (min-width: 1024px) {
      display: block;
    }
    @media (max-width: 1023px) {
      display: none;
    }

    table {
      width: 100%;

      thead {
        font-size: 13px;
        background-color: #f6f6f6;

        td {
          padding: 15px 10px 16px;
          height: 29px;
          line-height: 22px;
          vertical-align: middle;
        }
      }
      tbody {
        font-size: 13px;
        tr {
          &.emptyRow {
            td {
              padding: 56px 0;
            }
          }

          td {
            padding: 15px 10px 16px;
            border-bottom: 1px solid #e5e5e5;
            height: 29px;
            line-height: 22px;
            vertical-align: middle;
            white-space: pre-line;
            color: #555555;
          }
        }
      }
    }
  }
  .mobileListContainer {
    border-top: 2px solid #000;
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 1024px) {
      display: none;
    }
    @media (max-width: 1023px) {
      display: block;
    }

    .item {
      padding: 20px 8px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-bottom: 1px solid #e5e5e5;
      color: #6d6d6d;

      &.emptyRow {
        padding: 56px 0;
        text-align: center;
      }

      .topRow {
        display: flex;
        min-height: 28px;
        align-items: center;
      }
      .bottomRow {
        min-height: 28px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .searchContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px 5px;

    .select {
      border: 1px solid #e0e0e0;
      height: 40px;
      display: flex;
      align-items: center;
    }
    .selectInput {
      color: #000;
    }

    .dateContainer {
      @media (min-width: 1024px) {
        width: 83px;
      }
      @media (max-width: 1023px) {
        min-width: 83px;
        flex: 1;
      }
    }
    .typeContainer {
      @media (min-width: 1024px) {
        width: 110px;
      }
      @media (max-width: 1023px) {
        min-width: 110px;
        flex: 1;
      }
    }
    .textContainer {
      height: 40px;

      @media (min-width: 1024px) {
        width: 175px;
      }
      @media (max-width: 1023px) {
        min-width: 175px;
        flex: 2;
      }

      .inputBox {
        height: 38px;
        border: 1px solid #e0e0e0;
      }
      .inputBoxFocus {
        border-color: #000;
      }
    }
    .btnContainer {
      height: 38px;
      padding: 0 20px;
      font-size: 13px;
      border: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        border-color: #000;
      }

      @media (min-width: 1024px) {
        max-width: 70px;
      }
      @media (max-width: 1023px) {
        min-width: 70px;
        flex: 1;
      }
    }
  }
}
