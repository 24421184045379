.container {
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 10;
  background-color: #fff;

  @media (max-width: 1023px) {
    background-color: unset;
    width: 92%;
    padding: 0 4%;
    height: 65px;
  }

  @media (min-width: 1024px) {
    &.isHome {
      background-color: unset;
    }
  }

  &.menuOpen {
    @media (min-width: 1024px) {
      background-color: #fff;

      .toggleMenu {
        visibility: visible !important;
        height: auto !important;
      }
    }
    @media (max-width: 1023px) {
      display: none;
      background-color: unset;

      .toggleMenu {
        visibility: hidden !important;
        display: none;
        height: 0;
      }
    }

    .pcLogo {
      &.black {
        display: block !important;
      }
      &.white {
        display: none !important;
      }
    }
  }

  @media (min-width: 1024px) {
    &.isHome:not(&.menuOpen) {
      .menuContent {
        .item {
          .title {
            color: #ffffff;
          }
        }
      }

      .pcLogo {
        &.black {
          display: none;
        }
        &.white {
          display: block;
        }
      }
    }
  }

  .content {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 92%;
    margin: 0 4%;

    @media (max-width: 1023px) {
      margin: 0;
      align-items: center;
    }

    .menuIcon {
      width: 30px;
      height: 100%;

      img {
        width: 30px;
        height: auto;
      }

      @media (min-width: 1024px) {
        display: none;
      }

      @media (max-width: 1023px) {
        display: block;
      }
    }

    .logoContainer {
      height: 80px;
      display: flex;
      align-items: center;

      @media (max-width: 1023px) {
        flex: 1;
        justify-content: center;
        height: 65px;
      }

      .pcLogo {
        &.black {
          display: block;
        }
        &.white {
          display: none;
        }

        @media (min-width: 1024px) {
          width: 200px;
        }

        @media (max-width: 1023px) {
          display: none;
          width: 0;
          height: 0;
        }
      }

      .mobileLogo {
        @media (min-width: 1024px) {
          display: none;
        }

        @media (max-width: 1023px) {
          display: block;
          height: 29px;
          width: auto;
        }
      }
    }

    .menuContainer {
      flex: 1;
      display: flex;
      padding: 0 15vw;

      @media (max-width: 1023px) {
        display: none;
      }

      .menuContent {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .item {
          flex: 1;
          width: 25%;
          min-width: max-content;

          .title {
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            display: block;
            line-height: 78px;
            color: #3a3a3a;
            white-space: nowrap;
          }

          .toggleMenu {
            visibility: hidden;
            height: 0;

            @media (max-width: 1023px) {
              visibility: hidden !important;
              display: none !important;
            }

            .toggleItem {
              cursor: pointer;
              font-size: 12px;
              color: #9a9a9a;
              line-height: 1;
              font-weight: 400;
              display: block;
              padding: 10px 20px 10px 0;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.modalContainer {
  @media (min-width: 1024px) {
    display: none;
  }
  align-items: flex-start !important;

  .modal {
    height: 100svh;
    max-width: 80vw;
    width: 600px;
    background: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .logo {
      background-color: #f5f5f5;
      padding: 45px 0 23px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .logoImg {
        width: 24%;
        max-width: 40vw;
        height: auto;
      }
      .close {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .menuList {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      margin-top: 8px;
      padding: 30px 4%;

      .menuItem {
        .titleRow {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #1a1a1a;
          gap: 10px;
          padding: 5px 0;

          .title {
            cursor: pointer;
            flex: 1;
            font-size: 15px;
          }
          .toggleBtn {
            display: flex;
            cursor: pointer;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
          }
        }
        .toggleMenu {
          padding: 0 0 0 10px;

          .toggleItem {
            margin: 5px 0;
            font-size: 14px;
            line-height: 185%;
            color: #595959;
          }
        }
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: #ebebeb;
        margin-top: 40px;
      }
    }
  }
}
