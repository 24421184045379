.container {
  background-color: #2c2c2c;
  padding-bottom: 0;
  padding: 46px 0;

  .content {
    margin: auto;
    width: 92%;
    display: flex;
    flex-direction: row;

    .logo {
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;

      @media (min-width: 1024px) {
        margin-right: 120px;
      }
      @media (max-width: 1023px) {
        margin-right: 12px;
      }

      img {
        width: 60px;
        height: auto;

        @media (max-width: 1023px) {
          max-width: 50vw;
        }
      }
    }

    .row {
      flex: 1;
      display: flex;
      align-items: center;

      .info {
        color: #919191;
        font-size: 13px;
        font-weight: 500;

        .infoWrap {
          display: flex;
          flex-wrap: wrap;

          .infoDiv:before {
            content: "|";
            padding: 3px;
          }

          > div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 30px 0 !important;
  }
}
