.container {
  .logoSection {
    margin: auto;
    text-align: center;

    img {
      width: 52px;
      margin-bottom: 32px;
    }
  }
  .listContainer {
    display: flex;
    flex-direction: column;
    gap: 43px;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    max-width: min(90vw, 1600px);
    flex-wrap: wrap;
    word-break: keep-all;
    margin: 21px auto 0;

    .owner {
      margin-top: 10px;
    }
  }
}
