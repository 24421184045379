:root {
  /* color */
  --color-main: #141a46;

  --DEVICE-WIDTH: 100vw;
  --DEVICE-HEIGHT: 100vh;
}

* {
  scroll-behavior: smooth;

  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  word-break: keep-all;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
