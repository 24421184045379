.container {
  .imgContainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    max-height: 516px;

    @media (max-width: 1023px) {
      max-height: 300px;
    }

    .background {
      background: #00000080;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .textContainer {
      position: absolute;
      text-align: center;

      @media (min-width: 1024px) {
        padding-top: 140px;

        img {
          height: 870px;
        }
      }
      @media (max-width: 1023px) {
        img {
          height: 300px;
        }
      }

      .title {
        font-size: 30px;
        font-weight: 200;
        color: white;
        margin-bottom: 4px;
        font-family: "NanumMyeongjo";
      }

      .subTitle {
        font-size: 20px;
        color: white;
        font-weight: 200;
      }
    }
  }

  .navSection {
    width: 100%;
    background: #0d4455;
    padding: 37px 0;

    @media (max-width: 1023px) {
      padding: 25px 0;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;
      gap: 13px;

      @media (min-width: 1024px) {
        padding-right: 100px;
        padding-left: 100px;
      }
      @media (min-width: 1600px) {
        padding-right: 400px;
        padding-left: 400px;
      }
      @media (max-width: 1023px) {
        padding-right: 5vw;
        padding-left: 5vw;
      }

      .navItem {
        font-size: 12px;
        color: white;
        font-weight: 300;
        cursor: pointer;

        &:not(:first-child):before {
          content: "|";
          padding-right: 13px;
        }
      }
    }
  }
}
