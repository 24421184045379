.box {
  display: flex;
  flex: 1;
  overflow: hidden;
  font-size: 13px;
  border-radius: 0;
  min-height: 30px;

  .select {
    flex: 1;
    padding: 0;
    max-width: 100%;

    .control {
      min-height: 20px;
      height: auto;
      border: 1px solid var(--color-gray6);
      border-radius: 0;
      box-shadow: none;

      &.focus {
        border-color: var(--color-gray3);
      }

      > div {
        padding: 2px 0;
      }
    }

    .indicatorsContainer {
      padding: 0;

      [class$="-indicatorContainer"] {
        padding: 0 4px;
      }
      [class$="-indicatorSeparator"] {
        display: none;
      }
    }

    [class$="-placeholder"] {
      padding: 4px 8px;
      min-height: 20px;
    }
    [class$="-ValueContainer"] {
      padding: 0;
    }
    [class$="-multiValue"] {
      background: var(--color-gray7);
    }
    [class$="-MultiValueRemove"]:hover {
      background: none;
    }
  }

  select {
    flex: 1;
    border: none;
    outline: none;
    padding: 4px 8px;
    width: 100%;
  }
}
