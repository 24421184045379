.container {
  .titleRow {
    .title {
      color: #555555;
      font-size: 16px;
      font-weight: 700;
    }
    .subTitle {
      margin-top: 25px;
      font-size: 14px;
      color: #6d6d6d;
    }
  }

  .contentContainer {
    .contentTitleRow {
      border-top: 2px solid #000;
      border-bottom: 1px solid #eaeaea;
      margin-top: 42px;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media (max-width: 1023px) {
        margin-top: 20px;
      }

      .title {
        font-size: 18px;
      }
      .subTitleRow {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (min-width: 1024px) {
          gap: 16px;
        }
        @media (max-width: 1023px) {
          flex-direction: column;
        }

        .name {
        }
        .etc {
          color: #6d6d6d;
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          justify-content: center;

          .date {
          }
          .viewCnt {
          }
        }
      }
    }
    .content {
      font-size: 13px;
      line-height: 150%;
      white-space: pre-line;
      padding: 30px 20px;

      @media (max-width: 1023px) {
        padding: 30px 0;
      }
    }
  }
  .btnContainer {
    margin: 32px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      font-size: 14px;
      height: 52px;
      width: 135px;
      border: 1px solid #d6d6d6;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: #000;
      }

      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
}
